import { MenuItem, Select } from "@mui/material";
import React from "react";
import { styled } from "styled-components";


const StyledSelect = styled(Select)`
  margin-left: 5px;
`;

export function Selector({ options, selectedOptions, setSelectedOptions, toChange }) {
  return (
    <StyledSelect
      value={selectedOptions[toChange]}
      onChange={(e) =>
        setSelectedOptions({ ...selectedOptions, [toChange]: e.target.value })
      }
      sx={{ height: 25, fontSize: 14 }}
    >
      {options.map((option) => (
        <MenuItem value={option.value}>{option.label}</MenuItem>
      ))}
    </StyledSelect>
  );
}
import { ChartBox, SelectorBox } from "../boxes.jsx";
import React, { useState } from "react";
import { Bar, Line } from "react-chartjs-2";
import { Selector } from "./selector.jsx";

function addAlpha(rgb, alpha) {
  return rgb.replace(
    /^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/,
    `rgba($1, $2, $3, ${alpha})`
  );
}

export function Chart({
  borderWidth = 0,
  chartData,
  comparisonBorderDash = [],
  comparisonChartDataArray,
  chartType,
  displaySelectorBox = true,
  getValue,
  getYaxisTitle,
  label,
  pointRadius=3,
  sortLegend = false,
  xQuantity,
  yQuantity,
  ySuffix = null,
  yQuantityOptions = null,
  ySuffixOptions = null,
  xAxisTitle = null,
}) {
  const [selectedOptions, setSelectedOptions] = useState({
    yQuantity: yQuantity,
    ySuffix: ySuffix,
  });

  const xValues = chartData.rowData.map((item) => item[xQuantity]);

  let borderColor = chartData.rowData[0].color
    ? chartData.rowData[0].color
    : "rgb(54, 162, 235)";
  let backgroundColor = addAlpha(borderColor, 0.5);
  const data = {
    labels: xValues,
    datasets: [
      {
        label: chartData.rowData.map((item) => item[label])[0],
        data: chartData.rowData.map((item) =>
          getValue(item, selectedOptions.yQuantity, selectedOptions.ySuffix)
        ),
        borderColor: borderColor,
        backgroundColor: backgroundColor,
        borderWidth: borderWidth,
        pointRadius: pointRadius,
      },
    ],
  };

  function addComparisonChartData(comparisonChartData) {
    if (comparisonChartData.rowData) {
      const comparisonXvalues = comparisonChartData.rowData.map(
        (item) => item[xQuantity]
      );
      let comparisonYvalues = comparisonChartData.rowData.map((item) =>
        getValue(item, selectedOptions.yQuantity, selectedOptions.ySuffix)
      );
      const valueMap = new Map(
        comparisonXvalues.map((comparisonXvalue, index) => [
          comparisonXvalue,
          comparisonYvalues[index],
        ])
      );
      comparisonYvalues = xValues.map((xValue) =>
        valueMap.has(xValue) ? valueMap.get(xValue) : null
      );

      let color = comparisonChartData.rowData[0].color
        ? comparisonChartData.rowData[0].color
        : "rgb(255, 99, 132)";
      borderColor = addAlpha(color, 0.6);
      backgroundColor = addAlpha(color, 0.4);

      data["datasets"].push({
        label: comparisonChartData.rowData.map((item) => item[label])[0],
        data: comparisonYvalues,
        borderColor: borderColor,
        backgroundColor: backgroundColor,
        pointRadius: pointRadius,
        borderDash: comparisonBorderDash,
      });
    }
  }

  if (comparisonChartDataArray) {
    if (Array.isArray(comparisonChartDataArray)) {
      for (let i = 0; i < comparisonChartDataArray.length; i++) {
        addComparisonChartData(comparisonChartDataArray[i]);
      }
    } else {
      addComparisonChartData(comparisonChartDataArray);
    }
  }

  const options = {
    maintainAspectRatio: false,
    scales: {
      x: {
        title: {
          display: xAxisTitle != null,
          text: xAxisTitle,
        },
      },
      y: {
        title: {
          display: true,
          text: getYaxisTitle(
            selectedOptions.yQuantity,
            selectedOptions.ySuffix
          ),
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "top",
        labels: {
          boxWidth: 30,
          sort: sortLegend ? (a, b) => a.text.localeCompare(b.text) : null,
        },
      },
    },
  };

  return (
    <ChartBox>
      {displaySelectorBox && (
        <SelectorBox>
          {yQuantityOptions && (
            <Selector
              options={yQuantityOptions}
              selectedOptions={selectedOptions}
              setSelectedOptions={setSelectedOptions}
              toChange={"yQuantity"}
            />
          )}
          {ySuffixOptions && (
            <Selector
              options={ySuffixOptions}
              selectedOptions={selectedOptions}
              setSelectedOptions={setSelectedOptions}
              toChange={"ySuffix"}
            />
          )}
        </SelectorBox>
      )}
      {chartType == "bar" && <Bar data={data} options={options} />}
      {chartType == "line" && <Line data={data} options={options} />}
    </ChartBox>
  );
}

import { Chart } from "./chart.jsx";
import {
  Chart as ChartJS,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Legend,
} from "chart.js";
import React from "react";

export function LineChart({
  borderWidth=3,
  chartData,
  comparisonBorderDash = [],
  comparisonChartDataArray,
  displaySelectorBox = true,
  getValue,
  getYaxisTitle,
  label="player",
  pointRadius=3,
  sortLegend,
  xQuantity,
  yQuantity,
  ySuffix = null,
  yQuantityOptions = null,
  ySuffixOptions = null,
  xAxisTitle,
}) {
  ChartJS.register(
    LineController,
    LineElement,
    PointElement,
    LinearScale,
    CategoryScale,
    Legend,
  );

  return (
    <Chart
      borderWidth={borderWidth}
      chartData={chartData}
      comparisonBorderDash={comparisonBorderDash}
      comparisonChartDataArray={comparisonChartDataArray}
      chartType={"line"}
      displaySelectorBox={displaySelectorBox}
      getValue={getValue}
      getYaxisTitle={getYaxisTitle}
      label={label}
      pointRadius={pointRadius}
      sortLegend={sortLegend}
      xQuantity={xQuantity}
      yQuantity={yQuantity}
      ySuffix={ySuffix}
      yQuantityOptions={yQuantityOptions}
      ySuffixOptions={ySuffixOptions}
      xAxisTitle={xAxisTitle}
    />
  );
}

import {
  ChartsBox,
  Flexbox,
  SearchBarBox,
  TeamLogoBox,
} from "../components/boxes.jsx";
import { FooterSection } from "../components/footer.jsx";
import { HeaderSection } from "../components/header.jsx";
import { LineChart } from "../components/charts/lineChart.jsx";
import { Selector } from "../components/charts/selector.jsx";
import { Name, SearchBarText, TeamLogo } from "../components/misc.jsx";
import React, { useEffect, useState } from "react";
import { useFetch } from "../hooks/useFetch.jsx";
import { useParams } from "react-router-dom";
import Styled, { styled } from "styled-components";

const TeamName = styled(Name)`
  font-size: 3rem;
  @media (max-width: 600px) {
    white-space: normal;
    text-align: center;
  }
`;

const ResponsiveFlexbox = styled(Flexbox)`
  @media (max-width: 600px) {
    flex-direction: column;
    padding-top: 50px;
  }
`;

function BasicInfoSection({ basicInfo }) {
  return (
    <ResponsiveFlexbox>
      <TeamLogoBox>
        <TeamLogo
          src={`/images/team_logos/${basicInfo["tri_code"]}.svg`}
        ></TeamLogo>
      </TeamLogoBox>
      <TeamName>{basicInfo["team_full_name"]}</TeamName>
    </ResponsiveFlexbox>
  );
}

function PointsProgression({ division }) {
  const { triCode } = useParams();

  const [selectedOptions, setSelectedOptions] = useState({ season: "2024-25" });
  const seasons = [
    { value: "2023-24", label: "2023-24" },
    { value: "2024-25", label: "2024-25" },
  ];

  function getRowData(data) {
    return data;
  }

  const chartData = useFetch(
    null,
    getRowData,
    `teams/${selectedOptions["season"]}/${triCode}/trends`
  );

  const divisions = {
    atlantic: ["BOS", "BUF", "DET", "FLA", "MTL", "OTT", "TBL", "TOR"],
    metro: ["CAR", "CBJ", "NJD", "NYI", "NYR", "PHI", "PIT", "WSH"],
    central: ["CHI", "COL", "DAL", "MIN", "NSH", "STL", "UTA", "WPG"],
    pacific: ["ANA", "CGY", "EDM", "LAK", "SJS", "SEA", "VAN", "VGK"],
  };

  let comparisonChartDataArray = [];
  for (let i = 0; i < divisions[division].length; i++) {
    if (divisions[division][i] != triCode) {
      comparisonChartDataArray.push(
        useFetch(
          null,
          getRowData,
          `teams/${selectedOptions["season"]}/${divisions[division][i]}/trends`
        )
      );
    }
  }

  return (
    chartData["rowData"] && (
      <>
        <SearchBarBox>
          <SearchBarText>Select season:</SearchBarText>
          <Selector
            options={seasons}
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
            toChange={"season"}
          />
        </SearchBarBox>
        <ChartsBox>
          <LineChart
            borderWidth={4}
            chartData={chartData}
            displaySelectorBox={false}
            comparisonBorderDash={[10, 2]}
            comparisonChartDataArray={comparisonChartDataArray}
            getValue={(item) => item["points"]}
            getYaxisTitle={() => "team points"}
            label={"team_abbrev"}
            pointRadius={0}
            sortLegend={true}
            xQuantity={"game_number"}
            yQuantity={"points"}
            xAxisTitle={`game number (${selectedOptions["season"]} season)`}
          />
        </ChartsBox>
      </>
    )
  );
}

export function Team() {
  const { triCode } = useParams();
  const [basicInfo, setBasicInfo] = useState(null);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    fetch(`/data/teams.json`)
      .then((response) => response.json())
      .then((data) => {
        const basicInfo = data.filter((item) => item.tri_code == triCode)[0];
        setBasicInfo(basicInfo);
      });
  }, []);

  return (
    <>
      <HeaderSection
        basicInfoSection={
          basicInfo && <BasicInfoSection basicInfo={basicInfo} />
        }
      />
      {basicInfo && <PointsProgression division={basicInfo["division"]} />}
      <FooterSection />
    </>
  );
}

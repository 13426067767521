import { StartFlexbox } from "./boxes.jsx";
import { Link } from "react-router-dom";
import React from "react";
import Styled, { styled, css } from "styled-components";

const NavBarBox = styled(StartFlexbox)`
  background-color: ${(props) => props.theme.colors.nav};
  height: 40px;
`;

const NavBarText = Styled.span`
  color: white;
  font-family: "FranklinGothic";
  font-weight: normal;
  font-size: 1.25rem;
  margin-left: 20px;
  ${(props) =>
    props.selected &&
    css`
      text-decoration: underline;
      text-decoration-thickness: 5px;
      text-underline-offset: 5px;
    `}
`;

const NavBarLink = styled(Link)`
  &:link,
  &:visited {
    color: inherit;
  }
  text-decoration: none;
`;

function NavBarItem({ path, selected, text }) {
  return (
    <NavBarLink to={path}>
      <NavBarText selected={selected}>{text}</NavBarText>
    </NavBarLink>
  );
}

export function NavBar({ page }) {
  return (
    <NavBarBox>
      <NavBarItem
        path={"/home"}
        selected={page == "home"}
        text={"Players"}
      />
      <NavBarItem
        path={"/teams"}
        selected={page == "teams"}
        text={"Teams"}
      />
      <NavBarItem
        path={"/schedule"}
        selected={page == "schedule"}
        text={"Schedule"}
      />
    </NavBarBox>
  );
}

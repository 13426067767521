import { Link } from "react-router-dom";
import Styled, { styled } from "styled-components";

export const Dash = Styled.span`
  border-bottom: 2px solid ${(props) => props.theme.colors.title};
  flex: 1;
  margin: 0 10px;
`;

export const NoWrapP = Styled.p`
  white-space: nowrap;
`;

export const H3Normal = Styled.h3`
  font-weight: normal;
`;

export const H4Normal = Styled.h4`
  font-weight: normal;
`;

export const Title = Styled.h1`
	color: ${(props) => props.theme.colors.title};
  font-size: 2.3rem;
  font-weight: normal;
  margin-bottom: 10px;
  white-space: nowrap;
`;

export const StyledA = Styled.a`
  &:link, &:visited {
    color: ${(props) => props.theme.colors.link};
`;

export const StyledLink = styled(Link)`
  &:link,
  &:visited {
    color: ${(props) => props.theme.colors.link};
  }
`;

export const TeamLogo = Styled.img`
  height: 100%;
  object-fit: contain;
  width: 100%;
`;

export const SearchBarText = Styled.span`
  margin-left: 5px;
  margin-right: 5px;
`;

export const Name = styled(Title)`
  font-family: "FranklinGothic";
  font-size: 2rem;
  margin-bottom: 5px;
`;
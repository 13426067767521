import {
  ColumnFlexbox,
  Flexbox,
  MinContentBox,
  StartFlexbox,
} from "../components/boxes.jsx";
import { FooterSection } from "../components/footer.jsx";
import { HeaderSection } from "../components/header.jsx";
import { TeamLogo } from "../components/misc.jsx";
import React from "react";
import { Link } from "react-router-dom";
import Styled, { styled } from "styled-components";

const TeamsBox = styled(MinContentBox)`
  display: flex;
  @media (max-width: 1100px) {
    flex-direction: column;
  }
  margin-top: 50px;
`;

const Conference = Styled.div`
  display: flex;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;
const DivisionBox = Styled.div`
  margin: 10px;
`;

const DivisionText = Styled.span`
  color: ${(props) => props.theme.colors.title};
  font-family: "FranklinGothic";
  font-size: 1.5rem;
`;

function Division({ children, name }) {
  return (
    <DivisionBox>
      <DivisionText>{name}</DivisionText>
      {children}
    </DivisionBox>
  );
}

const TeamLogoBox = styled(Flexbox)`
  height: 30px;
  margin-right: 10px;
  width: 30px;
`;

const TeamBox = styled(StartFlexbox)`
  margin-bottom: 10px;
  margin-top: 10px;
`;

const StyledLink = styled(Link)`
  color: ${(props) => props.theme.colors.title};
  &:link,
  &:visited {
    color: ${(props) => props.theme.colors.title};
  }
  font-size: 1.3rem;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    text-decoration-thickness: 3px;
    text-underline-offset: 6px;
  }
  white-space: nowrap;
`;

function Team({ name, abbrev }) {
  return (
    <TeamBox>
      <TeamLogoBox>
        <TeamLogo
          src={`/images/team_logos/${name
            .toLowerCase()
            .replace(/ /g, "_")
            .replace(".", "")}.svg`}
        ></TeamLogo>
      </TeamLogoBox>
      <StyledLink to={`/team/${abbrev}`}>{name}</StyledLink>
    </TeamBox>
  );
}

export function Teams() {
  return (
    <>
      <HeaderSection page={"teams"} />
      <ColumnFlexbox>
        <TeamsBox>
          <Conference>
            <Division name={"Pacific"}>
              <Team name={"Anaheim Ducks"} abbrev={"ANA"} />
              <Team name={"Calgary Flames"} abbrev={"CGY"} />
              <Team name={"Edmonton Oilers"} abbrev={"EDM"} />
              <Team name={"Los Angeles Kings"} abbrev={"LAK"} />
              <Team name={"San Jose Sharks"} abbrev={"SJS"} />
              <Team name={"Seattle Kraken"} abbrev={"SEA"} />
              <Team name={"Vancouver Canucks"} abbrev={"VAN"} />
              <Team name={"Vegas Golden Knights"} abbrev={"VGK"} />
            </Division>
            <Division name={"Central"}>
              <Team name={"Chicago Blackhawks"} abbrev={"CHI"} />
              <Team name={"Colorado Avalanche"} abbrev={"COL"} />
              <Team name={"Dallas Stars"} abbrev={"DAL"} />
              <Team name={"Minnesota Wild"} abbrev={"MIN"} />
              <Team name={"Nashville Predators"} abbrev={"NSH"} />
              <Team name={"St. Louis Blues"} abbrev={"STL"} />
              <Team name={"Utah Hockey Club"} abbrev={"UTA"} />
              <Team name={"Winnipeg Jets"} abbrev={"WPG"} />
            </Division>
          </Conference>
          <Conference>
            <Division name={"Metropolitan"}>
              <Team name={"Carolina Hurricanes"} abbrev={"CAR"} />
              <Team name={"Columbus Blue Jackets"} abbrev={"CBJ"} />
              <Team name={"New Jersey Devils"} abbrev={"NJD"} />
              <Team name={"New York Rangers"} abbrev={"NYR"} />
              <Team name={"New York Islanders"} abbrev={"NYI"} />
              <Team name={"Philadelphia Flyers"} abbrev={"PHI"} />
              <Team name={"Pittsburgh Penguins"} abbrev={"PIT"} />
              <Team name={"Washington Capitals"} abbrev={"WSH"} />
            </Division>
            <Division name={"Atlantic"}>
              <Team name={"Boston Bruins"} abbrev={"BOS"} />
              <Team name={"Buffalo Sabres"} abbrev={"BUF"} />
              <Team name={"Detroit Red Wings"} abbrev={"DET"} />
              <Team name={"Florida Panthers"} abbrev={"FLA"} />
              <Team name={"Montréal Canadiens"} abbrev={"MTL"} />
              <Team name={"Ottawa Senators"} abbrev={"OTT"} />
              <Team name={"Tampa Bay Lightning"} abbrev={"TBL"} />
              <Team name={"Toronto Maple Leafs"} abbrev={"TOR"} />
            </Division>
          </Conference>
        </TeamsBox>
      </ColumnFlexbox>
      <FooterSection />
    </>
  );
}

import { BasicInfoContext } from "./basicInfoContext.jsx";
import { BarChart } from "../../components/charts/barChart.jsx";
import { ChartsBox, SearchBarBox } from "../../components/boxes.jsx";
import { FooterSection } from "../../components/footer.jsx";
import { Autocomplete, MenuItem, Select, TextField } from "@mui/material";
import { SearchBarText } from "../../components/misc.jsx";
import { useFetch } from "../../hooks/useFetch.jsx";
import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";

const averagePlayerIds = {
  "Average A tier goalie": 1000011,
  "Average B tier goalie": 1000012,
  "Average C tier goalie": 1000013,
  "Average D tier goalie": 1000014,
  "Average A tier forward": 1000021,
  "Average B tier forward": 1000022,
  "Average C tier forward": 1000023,
  "Average D tier forward": 1000024,
  "Average A tier defense": 1000031,
  "Average B tier defense": 1000032,
  "Average C tier defense": 1000033,
  "Average D tier defense": 1000034,
}

function SearchBar({ playerType, setComparisonPlayer }) {
  const [selectedValue, setSelectedValue] = useState(null);
  const [autocompleteValue, setAutocompleteValue] = useState(null);

  const basicInfo = useContext(BasicInfoContext);

  function getRowData(data) {
    return data;
  }

  const tableInfo = useFetch(
    null,
    getRowData,
    basicInfo["position"] == "G"? "goalies" : "skaters",
  )


  let capHitRanges = ["10-20%", "7.5-10%", "2.5-7.5%", "0-2.5%"];
  if (playerType == "forward") {
    capHitRanges = ["12-20%", "8-12%", "4-8%", "0-4%"];
  } else if (playerType == "defense") {
    capHitRanges = ["10-20%", "7.5-10%", "2.5-7.5%", "0-2.5%"];
  }

  return (
    <>
      {tableInfo["rowData"] && (
        <SearchBarBox>
          <SearchBarText>Select player to compare:</SearchBarText>
          <Select
            value={selectedValue}
            onChange={(e) => {
              setAutocompleteValue(null);
              setSelectedValue(e.target.value);
              setComparisonPlayer({ playerId: averagePlayerIds[e.target.value], player: e.target.value });
            }}
            sx={{ width: 300, height: 25, fontSize: 14 }}
          >
            {capHitRanges.map((capHitRange, index) => (
              <MenuItem
                value={`Average ${String.fromCharCode(
                  65 + index
                )} tier ${playerType}`}
              >{`Average ${String.fromCharCode(
                65 + index
              )} tier ${playerType} (${capHitRange} cap hit)`}</MenuItem>
            ))}
          </Select>
          <SearchBarText>or enter player's name:</SearchBarText>
          <Autocomplete
            value={autocompleteValue}
            autoHighlight={true}
            options={tableInfo["rowData"]}
            sx={{ width: 250 }}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ "& .MuiInputBase-root": { height: 25, fontSize: 14 } }}
              />
            )}
            onChange={(e, value) => {
              setAutocompleteValue(value?.label);
              setSelectedValue(null);
              setComparisonPlayer({
                playerId: value?.value,
                player: value?.label.split(",")[0],
              });
            }}
          />
        </SearchBarBox>
      )}
    </>
  );
}

function getTOI(item) {
  const [minutes, seconds] = item.time_on_ice.split(":").map(Number);
  return minutes + seconds / 60;
}

function getGoalieValue(item, quantity, suffix) {
  let value = item[quantity];
  if (quantity == "time_on_ice") {
    value = getTOI(item);
  }
  if (quantity == "goals_against") {
    value = (item["goals_against_average"] * item["mins_played"]) / 60;
  }
  if (quantity.includes("percentage") & (quantity != "save_percentage")) {
    value = item[quantity.split("_percentage")[0]] / item["games_played"];
  }
  if (suffix == "_per_60_min") {
    value /= item["mins_played"] / 60;
  }
  if (suffix == "_per_game") {
    value /= item["games_played"];
  }
  return value;
}

function getNonGoalieValue(item, quantity, suffix, playerType) {
  let value = item[quantity];
  if (quantity == "time_on_ice") {
    value = getTOI(item);
  }
  if (quantity == "goals_against") {
    value = (item["goals_against_average"] * item["mins_played"]) / 60;
  }
  if (quantity.includes("percentage") & (quantity != "save_percentage")) {
    value = item[quantity.split("_percentage")[0]] / item["games_played"];
  }
  if (suffix == "_per_60_min") {
    value /= (getTOI(item) * item["games_played"]) / 60;
  }
  if (suffix == "_per_game") {
    value /= item["games_played"];
  }
  return value;
}

function getYaxisTitle(quantity, suffix) {
  let yAxisTitle = quantity;
  if (["_per_60_min", "_per_game"].includes(suffix)) {
    yAxisTitle += suffix;
  }
  if (quantity == "time_on_ice") {
    yAxisTitle += " (min)";
  }
  return yAxisTitle.replace(/_/g, " ");
}

export function HeadToHeadTab() {
  const basicInfo = useContext(BasicInfoContext);
  let playerType = basicInfo["position_long"].toLowerCase();
  if (!["goalie", "defense"].includes(playerType)) {
    playerType = "forward";
  }

  const { playerId } = useParams();

  const [comparisonPlayer, setComparisonPlayer] = useState({
    playerId: averagePlayerIds[`Average ${basicInfo["salary_grade"]} tier ${playerType}`],
    player: `Average ${basicInfo["salary_grade"]} tier ${playerType}`,
  });

  const getValue = playerType == "goalie"? getGoalieValue: getNonGoalieValue;
  function getRowData(data) {
    return data;
  }

  const chartData = useFetch(
    null,
    getRowData,
    `players/${playerId}/basic_stats`,
  );

  const comparisonChartData = useFetch(
    null,
    getRowData,
    `players/${comparisonPlayer.playerId}/basic_stats`
  )

  return (
    <>
      <SearchBar
        playerType={playerType}
        setComparisonPlayer={setComparisonPlayer}
      />
      {chartData.rowData && comparisonChartData.rowData && (
        <>
          <ChartsBox>
            {playerType == "goalie" ? (
              <>
                <BarChart
                  chartData={chartData}
                  comparisonChartData={comparisonChartData}
                  getValue={getValue}
                  getYaxisTitle={getYaxisTitle}
                  xQuantity={"season"}
                  yQuantity={"save_percentage"}
                  yQuantityOptions={[
                    { value: "save_percentage", label: "save (%)" },
                    { value: "wins_percentage", label: "wins (%)" },
                    { value: "losses_percentage", label: "losses (%)" },
                  ]}
                />
                <BarChart
                  chartData={chartData}
                  comparisonChartData={comparisonChartData}
                  getValue={getValue}
                  getYaxisTitle={getYaxisTitle}
                  xQuantity={"season"}
                  yQuantity={"goals_against"}
                  ySuffix={"_per_60_min"}
                  yQuantityOptions={[
                    { value: "goals_against", label: "goals against average" },
                    { value: "saves", label: "saves" },
                  ]}
                  ySuffixOptions={[
                    { value: "_per_60_min", label: "per 60 min" },
                    { value: "_per_game", label: "per game" },
                    { value: "total", label: "total" },
                  ]}
                />
                <BarChart
                  chartData={chartData}
                  comparisonChartData={comparisonChartData}
                  getValue={getValue}
                  getYaxisTitle={getYaxisTitle}
                  xQuantity={"season"}
                  yQuantity={"games_played"}
                  yQuantityOptions={[
                    { value: "games_played", label: "games played" },
                    { value: "games_started", label: "games started" },
                    { value: "mins_played", label: "mins played" },
                  ]}
                />
              </>
            ) : (
              <>
                <BarChart
                  chartData={chartData}
                  comparisonChartData={comparisonChartData}
                  getValue={getValue}
                  getYaxisTitle={getYaxisTitle}
                  xQuantity={"season"}
                  yQuantity={"goals"}
                  ySuffix={"_per_game"}
                  yQuantityOptions={[
                    { value: "goals", label: "goals" },
                    { value: "assists", label: "assists" },
                    { value: "points", label: "points" },
                    { value: "shots", label: "shots" },
                  ]}
                  ySuffixOptions={[
                    { value: "_per_game", label: "per game" },
                    { value: "_per_60_min", label: "per 60 min" },
                    { value: null, label: "total" },
                  ]}
                />
                <BarChart
                  chartData={chartData}
                  comparisonChartData={comparisonChartData}
                  getValue={getValue}
                  getYaxisTitle={getYaxisTitle}
                  xQuantity={"season"}
                  yQuantity={"time_on_ice"}
                />
              </>
            )}
          </ChartsBox>
          <FooterSection />
        </>
      )}
    </>
  );
}

import { Flexbox, StartFlexbox } from "./boxes.jsx";
import { NavBar } from "./nav.jsx";
import React from "react";
import { Link } from "react-router-dom";
import Styled, { styled } from "styled-components";

const ResponsiveFlexbox = styled(StartFlexbox)`
  padding-top: 10px;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const MarginTopFlexbox = styled(Flexbox)`
  margin-top: 20px;
`;

const UndecoratedLink = styled(Link)`
  text-decoration: none;
`;

const LogoBox = Styled.div`
  height: auto;
  padding-left: ${(props) => (props.padding ? "20px" : "0")};
  padding-top: ${(props) => (props.padding ? "10px" : "0")};
  width: ${(props) => props.width};
  @media (max-width: 800px) {
    padding-left: 20px;
  }
`;

const Logo = Styled.img`
  height: 100%;
  object-fit: contain;
  width: 100%;
`;

const BasicInfoSectionBox = Styled.div`
  @media (min-width: 900px) {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export function HeaderSection({
  basicInfoSection = null,
  link = true,
  navVisible = true,
  page = null,
  width = "150px",
}) {
  return (
    <>
      {navVisible && <NavBar page={page}/>}
      {basicInfoSection ? (
        <ResponsiveFlexbox>
          <UndecoratedLink to="/home">
            <LogoBox padding={true} width={width}>
              <Logo src={"/images/logo_Tape_to_Tape.svg"} />
            </LogoBox>
          </UndecoratedLink>
          <BasicInfoSectionBox>{basicInfoSection}</BasicInfoSectionBox>
        </ResponsiveFlexbox>
      ) : (
        <MarginTopFlexbox>
          {link ? (
            <UndecoratedLink to="/home">
              <LogoBox padding={false} width={width}>
                <Logo src={"/images/logo_Tape_to_Tape.svg"} />
              </LogoBox>
            </UndecoratedLink>
          ) : (
            <LogoBox padding={false} width={width}>
              <Logo src={"/images/logo_Tape_to_Tape.svg"} />
            </LogoBox>
          )}
        </MarginTopFlexbox>
      )}
    </>
  );
}

import { FooterSection } from "../components/footer.jsx";
import { HeaderSection } from "../components/header.jsx";
import { useFetch } from "../hooks/useFetch.jsx";
import { PlayerRenderer, Table, TeamRenderer } from "../components/table.jsx";
import React, { useState } from "react";
import Styled from "styled-components";
import { baseColumnDef, extendBaseColumnDef } from "../utils/table.jsx";

function ClauseTooltipValueGetter(params) {
  if (params.value == "NMC") {
    return "no-move clause";
  }
  if (params.value == "NTC") {
    return "no-trade clause";
  }
  if (params.value == "M-NTC") {
    return "limited, or modified no-trade clause";
  }
  if (params.value == "M-NTC, NMC") {
    return "limited, or modified no-trade and no-move clauses";
  }
  if (params.value == "NTC, NMC") {
    return "no-trade and no-move clauses";
  }
}

function StatusTooltipValueGetter(params) {
  if (params.value == "UFA") {
    return "unrestricted free agent";
  }
  if (params.value == "RFA") {
    return "restricted free agent";
  }
}

function SalaryRenderer(params) {
  return (
    <span>{params.value == 0 ? "" : `$${params.value.toLocaleString()}`}</span>
  );
}

const HomePageTableBox = Styled.div`
  margin-top: 0;
`;

function getColumnDef(key) {
  if (key == "player") {
    return extendBaseColumnDef(key, { cellRenderer: PlayerRenderer });
  } else if (key == "team") {
    return extendBaseColumnDef(key, { cellRenderer: TeamRenderer });
  } else if (key == "age") {
    return extendBaseColumnDef(key, {
      filter: "agNumberColumnFilter",
    });
  } else if (key === "clause") {
    return extendBaseColumnDef(key, {
      tooltipValueGetter: ClauseTooltipValueGetter,
    });
  } else if (key == "status_at_expiration") {
    return extendBaseColumnDef(key, {
      tooltipValueGetter: StatusTooltipValueGetter,
    });
  } else if (key == "2024-25") {
    return extendBaseColumnDef(key, {
      maxWidth: 125,
      filter: "agNumberColumnFilter",
      cellRenderer: SalaryRenderer,
      sort: "desc",
    });
  } else if (key.includes("20")) {
    return extendBaseColumnDef(key, {
      maxWidth: 125,
      filter: "agNumberColumnFilter",
      cellRenderer: SalaryRenderer,
    });
  } else {
    return baseColumnDef(key);
  }
}

function getRowData(data) {
  return data;
}

function HomePageTable({ visible, setVisible }) {
  const tableInfo = useFetch(getColumnDef, getRowData, "home", null, false);

  return (
    <HomePageTableBox>
      <Table
        pagination={true}
        quickFilter={true}
        setVisible={setVisible}
        tableInfo={tableInfo}
        visible={visible}
      />
    </HomePageTableBox>
  );
}

export function Home() {
  const [visible, setVisible] = useState(false);
  return (
    <>
      <HeaderSection page={"home"} />
      <HomePageTable visible={visible} setVisible={setVisible} />
      <FooterSection visible={visible} />
    </>
  );
}

import { Chart } from "./chart.jsx";
import {
  Chart as ChartJS,
  Colors,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";
import React from "react";

export function BarChart({
  chartData,
  comparisonChartData,
  getValue,
  getYaxisTitle,
  xQuantity,
  yQuantity,
  ySuffix = null,
  yQuantityOptions = null,
  ySuffixOptions = null,
}) {
  ChartJS.register(
    Colors,
    BarElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend
  );

  return (
    <Chart
      chartData={chartData}
      comparisonChartDataArray={comparisonChartData}
      chartType={"bar"}
      getValue={getValue}
      getYaxisTitle={getYaxisTitle}
      xQuantity={xQuantity}
      yQuantity={yQuantity}
      ySuffix={ySuffix}
      yQuantityOptions={yQuantityOptions}
      ySuffixOptions={ySuffixOptions}
      label={"player"}
    />
  );
}

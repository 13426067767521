import { FooterSection } from "../../components/footer.jsx";
import { useFetch } from "../../hooks/useFetch.jsx";
import { Table, TeamRenderer } from "../../components/table.jsx";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { baseColumnDef, extendBaseColumnDef } from "../../utils/table.jsx";

export function BasicStatsTab() {
  const { playerId } = useParams();
  const [visible, setVisible] = useState(false);

  function getColumnDef(key) {
    if (["player", "sweater_number"].includes(key)) {
      return extendBaseColumnDef(key, { hide: true });
    } else if (key == "season") {
      return extendBaseColumnDef(key, { sort: "desc" });
    } else if (key == "team") {
      return extendBaseColumnDef(key, { cellRenderer: TeamRenderer });
    } else if (key == "saves") {
      return extendBaseColumnDef(key, { maxWidth: 100 });
    } else if (key == "mins_played") {
      return extendBaseColumnDef(key, { maxWidth: 140 });
    } else {
      return baseColumnDef(key);
    }
  }

  function getRowData(data) {
    return data;
  }

  const tableInfo = useFetch(
    getColumnDef,
    getRowData,
    `players/${playerId}/basic_stats`
  );

  return (
    <>
      <Table setVisible={setVisible} tableInfo={tableInfo} visible={visible} />
      <FooterSection visible={visible} />
    </>
  );
}

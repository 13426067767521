import { ColumnFlexbox, Flexbox } from "../components/boxes.jsx";
import { Button } from "../components/button.jsx";
import { Dash, H3Normal, H4Normal, StyledA } from "../components/misc.jsx";
import { FooterSection } from "../components/footer.jsx";
import { HeaderSection } from "../components/header.jsx";
import React from "react";
import { Link } from "react-router-dom";
import Styled, { styled } from "styled-components";

const EnterButtonColumnFlexbox = styled(ColumnFlexbox)`
  margin-top: 50px;
  margin-bottom: 20px;
`;

const EnterButtonLink = styled(Link)`
  color: inherit;
  text-decoration: none;

  &:visited {
    color: inherit;
  }
`;

function EnterButton() {
  return (
    <>
      <EnterButtonColumnFlexbox>
        <Button
          ButtonContent={
            <EnterButtonLink to="/home">
              <H3Normal>Enter</H3Normal>
            </EnterButtonLink>
          }
        />
      </EnterButtonColumnFlexbox>
    </>
  );
}

const SectionTitleBox = styled(Flexbox)`
  padding-bottom: 20px;
  padding-top: 50px;
  margin: 0 auto;
  width: 250px;
`;

const SectionContentBox = Styled.div`
  text-align: center;
  width: 50%;
  @media (max-width: 800px) {
    width: 90%; 
  }
`;

const SectionTitle = Styled.h3`
  font-family: 'FranklinGothic';
  color: ${(props) => props.theme.colors.title};
`;

function Section({ SectionTitleText, SectionContent }) {
  return (
    <ColumnFlexbox>
      <SectionTitleBox>
        <Dash />
        <SectionTitle>{SectionTitleText}</SectionTitle>
        <Dash />
      </SectionTitleBox>
      <SectionContentBox>{SectionContent}</SectionContentBox>
    </ColumnFlexbox>
  );
}

function AboutSectionContent() {
  return (
    <H4Normal>
      Welcome to Tape-To-Tape Analytics. <br />
      <br /> We've combined our extensive knowledge of sports and data into this
      site. We hope to answer some questions that we felt were re-occurring
      within the NHL. This is an early version, call it a prototype. New metrics
      and tables are constantly being added. <br />
      <br /> We would love to hear your feedback. Please contact us at{" "}
      <StyledA href="mailto:contact@wyattai.com">contact@wyattai.com</StyledA>.
    </H4Normal>
  );
}

function DisclaimerSectionContent() {
  return (
    <H4Normal>
      Tape-to-Tape Analytics is not in any way affiliated to the NHL, NHLPA, or
      any NHL franchise. If there is any content or any image you would like
      removed, please contact us at{" "}
      <StyledA href="mailto:contact@wyattai.com">contact@wyattai.com</StyledA>.
    </H4Normal>
  );
}

export function About() {
  return (
    <>
      <HeaderSection link={false} width={"250px"} navVisible={false} />
      <EnterButton />
      <Section
        SectionTitleText={"About"}
        SectionContent={<AboutSectionContent />}
      />
      <Section
        SectionTitleText={"Disclaimer"}
        SectionContent={<DisclaimerSectionContent />}
      />
      <FooterSection navVisible={false} />
    </>
  );
}

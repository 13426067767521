import React from "react";
import Styled from "styled-components";

const ButtonContainer = Styled.div`
  color: ${(props) => (props.active ? "white" : props.theme.colors.title)};
  cursor: pointer;
  background-color: ${(props) =>
    props.active ? props.theme.colors.title : "inherit"};
  border: 2px solid ${(props) => props.theme.colors.title};
  border-radius: 10px;
  margin: ${(props) => props.margin};
  padding: 5px;
  text-align: center;
  transition: all 0.3s ease;
  width:${(props) => props.width}; 
  flex-shrink: 0;

  ${(props) =>
    !props.active &&
    `

  &:hover {
    background-color: ${props.theme.colors.hover};
    border-color: ${props.theme.colors.hover};
    color: white;
    `}
  }

`;

export function Button({
  active = false,
  ButtonContent,
  margin = 0,
  onClick,
  width = "150px",
}) {
  return (
    <ButtonContainer
      active={active}
      margin={margin}
      onClick={onClick}
      width={width}
    >
      {ButtonContent}
    </ButtonContainer>
  );
}

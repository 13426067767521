import { BasicInfoContext } from "./basicInfoContext.jsx";
import { BasicInfoSection } from "./basicInfoSection.jsx";
import { BasicStatsTab } from "./basicStatsTab.jsx";
import { Flexbox } from "../../components/boxes.jsx";
import { Button } from "../../components/button.jsx";
import { HeaderSection } from "../../components/header.jsx";
import { HeadToHeadTab } from "./headToHeadTab.jsx";
import { H3Normal } from "../../components/misc.jsx";
import React, { useContext, useEffect, useRef, useState } from "react";
import { PlayedWithTab } from "./playedWithTab.jsx";
import { useParams } from "react-router-dom";
import { styled } from "styled-components";
import { TrendsTab } from "./trendsTab.jsx";

const TabButtonContent = styled(H3Normal)`
  white-space: nowrap;
`;

function TabButton({ activeTab, setActiveTab, tabName }) {
  return (
    <Button
      active={activeTab == tabName}
      ButtonContent={<TabButtonContent>{tabName}</TabButtonContent>}
      margin={"5px"}
      onClick={() => {
        if (activeTab != tabName) {
          setActiveTab(tabName);
        }
      }}
    />
  );
}

function TabContent({ activeTab }) {
  if (activeTab == "Basic Stats") {
    return <BasicStatsTab />;
  } else if (activeTab == "Head-to-Head") {
    return <HeadToHeadTab />;
  } else if (activeTab == "Played With") {
    return <PlayedWithTab />;
  } else {
    return <TrendsTab />;
  }
}

const TabButtonsBox = styled(Flexbox)`
  margin-bottom: 30px;
  margin-top: 60px;
  @media (max-width: 900px) {
    justify-content: start;
    overflow-x: auto;
  }
`;

function Tabs() {
  const basicInfo = useContext(BasicInfoContext);
  const [activeTab, setActiveTab] = useState("Basic Stats");

  return (
    basicInfo && (
      <>
        <TabButtonsBox>
          <TabButton
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            tabName={"Basic Stats"}
          />
          {basicInfo["position"] != "G" && (
            <TabButton
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              tabName={"Played With"}
            />
          )}
          <TabButton
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            tabName={"Head-to-Head"}
          />
          {basicInfo["position"] != "G" && (
            <TabButton
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              tabName={"Trends"}
            />
          )}
        </TabButtonsBox>
        <TabContent activeTab={activeTab} />
      </>
    )
  );
}

export function Player() {
  const [basicInfo, setBasicInfo] = useState(null);

  const playerId = useParams();
  const prevPlayerIdRef = useRef();

  // FIXME: this can be removed if we switch from HashRouter to BrowserRouter
  useEffect(() => {
    const prevPlayerId = prevPlayerIdRef.current;
    prevPlayerIdRef.current = playerId;
    if (prevPlayerId) {
      window.location.reload();
    }
  }, [playerId]);

  return (
    <>
      <BasicInfoContext.Provider value={basicInfo}>
        <HeaderSection
          basicInfoSection={<BasicInfoSection setBasicInfo={setBasicInfo} />}
        />
        <Tabs />
      </BasicInfoContext.Provider>
    </>
  );
}

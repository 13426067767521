import { Flexbox } from "../components/boxes.jsx";
import { Button } from "../components/button.jsx";
import { FooterSection } from "../components/footer.jsx";
import { HeaderSection } from "../components/header.jsx";
import { H4Normal } from "../components/misc.jsx";
import { useFetch } from "../hooks/useFetch.jsx";
import { Table, TeamRenderer } from "../components/table.jsx";
import React, { useState } from "react";
import Styled, { styled } from "styled-components";
import { baseColumnDef, extendBaseColumnDef } from "../utils/table.jsx";

const ScheduleTableBox = Styled.div`
    margin-top: 0;
`;

const ButtonsBox = styled(Flexbox)`
  margin-bottom: 30px;
  margin-top: 30px;
  @media (max-width: 900px) {
    justify-content: start;
    overflow-x: auto;
  }
`;

const ButtonContent = styled(H4Normal)`
  white-space: nowrap;
`;

function getColumnDef(key) {
  if ((key == "home_team") | (key == "away_team")) {
    return extendBaseColumnDef(key, { cellRenderer: TeamRenderer });
  } else if (key == "date_formatted") {
    return extendBaseColumnDef(key, { hide: true });
  } else {
    return baseColumnDef(key);
  }
}

function ScheduleTable({ visible, setVisible }) {
  const [pastGames, setPastGames] = useState(false);
  const [leagues, setLeagues] = useState({
    NHL: true,
    AHL: true,
    WHL: true,
    OHL: true,
    QMJHL: true,
    USHL: true,
  });

  function getRowData(data) {
    if (!pastGames) {
      data = data.filter(
        (item) => !["COMPLETED", "POSTPONED"].includes(item.time)
      );
    }
    for (let key in leagues) {
      if (!leagues[key]) {
        data = data.filter((item) => item.league != key);
      }
    }
    return data;
  }

  const tableInfo = useFetch(
    getColumnDef,
    getRowData,
    "schedule",
    null,
    false,
    (extraDependencies = [pastGames, leagues])
  );

  return (
    <>
      <ButtonsBox>
        <Button
          active={pastGames}
          ButtonContent={<ButtonContent>Past Games</ButtonContent>}
          margin={"5px"}
          onClick={() => {
            setPastGames(!pastGames);
          }}
          width={"auto"}
        />
        {Object.entries(leagues).map(([key, value]) => (
          <Button
            active={value}
            ButtonContent={<ButtonContent>{key}</ButtonContent>}
            margin={"5px"}
            onClick={() => {
              setLeagues({ ...leagues, [key]: !value });
            }}
            width={"auto"}
          />
        ))}
      </ButtonsBox>
      <ScheduleTableBox>
        <Table
          pagination={true}
          quickFilter={true}
          setVisible={setVisible}
          tableInfo={tableInfo}
          visible={visible}
        />
      </ScheduleTableBox>
    </>
  );
}

export function Schedule() {
  const [visible, setVisible] = useState(false);
  return (
    <>
      <HeaderSection page={"schedule"} />
      <ScheduleTable visible={visible} setVisible={setVisible} />
      <FooterSection visible={visible} />
    </>
  );
}

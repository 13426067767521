import { BasicInfoContext } from "./basicInfoContext.jsx";
import { ChartsBox, SearchBarBox } from "../../components/boxes.jsx";
import { LineChart } from "../../components/charts/lineChart.jsx";
import { MenuItem, Select } from "@mui/material";
import { SearchBarText } from "../../components/misc.jsx";
import React, { useContext, useState } from "react";
import { useFetch } from "../../hooks/useFetch.jsx";
import { useParams } from "react-router-dom";

function getValue(item, quantity, suffix){
  return item[`${quantity}_${suffix}`];
}

function getYaxisTitle(quantity, suffix) {
  let yAxisTitle = "fraction of on-ice time while team is ";
  if (suffix == "even") {
    yAxisTitle += "at even strength";
  }
  if (suffix == "adv") {
    yAxisTitle += "on the advantage";
  }
  if (suffix == "disadv") {
    yAxisTitle += "on the disadvantage";
  }
  return yAxisTitle;
}

export function TrendsTab() {
  const basicInfo = useContext(BasicInfoContext);
  const [selectedValue, setSelectedValue] = useState(basicInfo["salary_grade"]);
  const { playerId } = useParams();

  function getRowData(data) {
    return data;
  }

  const chartData = useFetch(
    null,
    getRowData,
    `players/${playerId}/trends`
  );

  let positionIndex = 2;
  let capHitRanges = ["12-20%", "8-12%", "4-8%", "0-4%"];
  let playerType = "forward";
  if (basicInfo["position"] == "G") {
    positionIndex = 1;
    capHitRanges = ["10-20%", "7.5-10%", "2.5-7.5%", "0-2.5%"];
    playerType = "goalie";
  }
  if (basicInfo["position"] == "D") {
    positionIndex = 3;
    capHitRanges = ["10-20%", "7.5-10%", "2.5-7.5%", "0-2.5%"];
    playerType = "defense";
  }
  const comparisonChartData = useFetch(
    null,
    getRowData,
    `players/10000${positionIndex}${
      selectedValue.charCodeAt(0) - 64
    }/trends`
  );

  const salaryGrades = ["A", "B", "C", "D"];

  return (
    <>
      {chartData["rowData"] && (
        <>
          <SearchBarBox>
            <SearchBarText>Select player to compare:</SearchBarText>
            <Select
              value={selectedValue}
              onChange={(e) => {
                setSelectedValue(e.target.value);
              }}
              sx={{ width: 300, height: 25, fontSize: 14 }}
            >
              {salaryGrades.map((salaryGrade, index) => (
                <MenuItem
                  value={salaryGrade}
                >{`Average ${salaryGrade} tier ${playerType} (${capHitRanges[index]} cap hit)`}</MenuItem>
              ))}
            </Select>
          </SearchBarBox>
          <ChartsBox>
            <LineChart
              chartData={chartData}
              comparisonChartDataArray={comparisonChartData}
              getValue={getValue}
              getYaxisTitle={getYaxisTitle}
              xQuantity={"game_number"}
              yQuantity={"rolling_mean_ratio_toi"}
              ySuffix={"even"}
              yQuantityOptions={[
                { value: "ratio_toi", label: "actual values" },
                { value: "rolling_mean_ratio_toi", label: "rolling mean" },
              ]}
              ySuffixOptions={[
                { value: "even", label: "even" },
                { value: "adv", label: "advantage" },
                { value: "disadv", label: "disadvantage" },
              ]}
              xAxisTitle={"game number (2024-25 season)"}
            />
          </ChartsBox>
        </>
      )}
    </>
  );
}
